@import url('https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600&family=Montserrat:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #000926;
  @apply text-white;
}
